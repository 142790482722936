<template functional>
  <div class="mb-4">
    <h3 class="text-black text-2xl mb-0 font-semibold">
      <slot></slot>
    </h3>
    <p class="text-sm font-light text-gray-500">
      <slot name="text"></slot>
    </p>
  </div>
</template>

<script>
export default {
  name: 'TabHeaders',
}
</script>
